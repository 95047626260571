import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components";
import * as styles from "../css/styles.module.css"

const GalleryWrapper = styled.div`    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const ContentWrapper = styled.div`
    align-self: center;
`;


const Gallery = () => (
  <Layout>
    <Seo title="Gallery" />
    <GalleryWrapper>
      <ContentWrapper>
    <h1>Gallery</h1>
    <p>Welcome to the gallery page</p>
    <Link to="/">Go back to the homepage</Link>
    </ContentWrapper>
    </GalleryWrapper>
  </Layout>
)

export default Gallery
